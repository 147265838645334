import { Serializable } from './serializable'
import { User } from './user'

export class AuthResult extends Serializable {

  user: User
  error: string

  constructor(data: any) {
    super(data)
    if (data.user) {
      this.user = new User(data.user)
    }
  }
}
