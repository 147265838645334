import { NgModule } from '@angular/core'

import { BrowserModule } from '@angular/platform-browser'
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { RouterModule } from '@angular/router'

import { AuthGuard } from './guards/auth.guard'

import { AppComponent } from './app.component'
import { HomeComponent } from './components/pages/home/home.component'
import { ProjectsComponent } from './components/pages/projects/projects.component'
import { MainMenuComponent } from './components/subcomponents/main-menu/main-menu.component'
import { FooterComponent } from './components/subcomponents/footer/footer.component'
import { SignInComponent } from './components/pages/sign-in/sign-in.component'
import { HeaderComponent } from './components/subcomponents/header/header.component'
import { ListProjectComponent } from './components/subcomponents/list-project/list-project.component'
import { CreateProjectComponent } from './components/pages/create-project/create-project.component'

import { CurrentUserService } from './services/current-user.service'
import { ProjectDataService } from './services/project-data.service'
import { ReportComponent } from './components/pages/report/report.component'
import { IntroComponent } from './components/pages/intro/intro.component'
import { WhyComponent } from './components/pages/why/why.component'
import { AboutComponent } from './components/pages/about/about.component'
import { PasswordResetComponent } from './components/pages/password-reset/password-reset.component'
import { PasswordVerifyComponent } from './components/pages/password-verify/password-verify.component'
import { PrivacyComponent } from './components/pages/privacy/privacy.component'



const ROUTES = [
  { path: '', component: HomeComponent,
    children: [
      { path: '', component: ProjectsComponent, canActivate: [AuthGuard] },
      { path: 'create', component: CreateProjectComponent, canActivate: [AuthGuard] }
    ]
  },
  { path: 'about', component: AboutComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'report/:projectId', component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'password_reset', component: PasswordResetComponent },
  { path: 'password_verify/:token', component: PasswordVerifyComponent }
]



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProjectsComponent,
    MainMenuComponent,
    FooterComponent,
    SignInComponent,
    HeaderComponent,
    ListProjectComponent,
    CreateProjectComponent,
    ReportComponent,
    IntroComponent,
    WhyComponent,
    AboutComponent,
    PasswordResetComponent,
    PasswordVerifyComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, {
      scrollPositionRestoration: 'enabled'
    }),
  ],
  providers: [
    CurrentUserService,
    ProjectDataService
  ],
  bootstrap: [AppComponent]
})



export class AppModule { }
