import { Component, OnInit } from '@angular/core'
import { ProjectDataService } from '../../../services/project-data.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.less']
})
export class ProjectsComponent implements OnInit {



  constructor(
    private projectDataService: ProjectDataService,
    private router: Router
  ) { }



  ngOnInit() {
    this.projectDataService.refreshProjects(() => {
      if (this.projectDataService.projects.length === 0) {
        this.router.navigate(['create'])
      }
    })
  }

}
