import { Serializable } from './serializable'

export class User extends Serializable {

  id: number
  name: string
  email: string

  createdAt: Date
  lastVisited: Date

  constructor(userData: any) {
    super(userData)
    if (userData) {
    }
  }

}
