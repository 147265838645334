import { Component, Input, OnInit } from '@angular/core'
import { Project } from '../../../models/project'
import {ProjectDataService} from '../../../services/project-data.service';

@Component({
  selector: 'app-list-project',
  templateUrl: './list-project.component.html',
  styleUrls: ['./list-project.component.less']
})
export class ListProjectComponent implements OnInit {



  @Input() project: Project



  constructor(
    private projectDataService: ProjectDataService
  ) { }

  ngOnInit() {
  }



  delete() {
    this.projectDataService.deleteProject(this.project)
  }



}
