import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { CurrentUserService } from '../../../services/current-user.service'

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.less']
})
export class MainMenuComponent implements OnInit {

  constructor(
    private router: Router,
    private currentUserService: CurrentUserService
  ) { }

  ngOnInit() {
  }

  signOut() {
    this.currentUserService.signOut().subscribe(
      data => {
        this.router.navigate(['/'])
      },
      error => {
        console.log('Error signing out.')
      })
  }

}
