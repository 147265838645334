import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class PasswordResetService {



  constructor(
    private http: HttpClient
  ) { }

  postPasswordResetToken(email: string) {
    return this.http.post<any>('/api/1.0/password_reset/create', {email})
  }

  getCheckPasswordResetToken(token: String) {
    return this.http.get<any>(`/api/1.0/password_reset/check/${token}`)
  }

  postNewPassword(password: string, token: string) {
    return this.http.post<any>('/api/1.0/password_reset/new_password', {password, token})
  }

}
