import { Serializable } from './serializable'

export class ProjectVariable extends Serializable {

  id: number
  projectId: number

  key: string
  value: string

  createdAt: Date

  constructor(data: any) {
    super(data)
    if (data) {
    }
  }

}
