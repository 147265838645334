import { Serializable } from './serializable'

export class NewUser extends Serializable {

  username: string
  name: string
  password: string
  passwordMatch: string
  email: string

}
