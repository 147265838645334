import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentUserService } from '../../../services/current-user.service';
import { NewUser } from '../../../models/new_user';
import { AuthResult } from '../../../models/auth_result';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.less']
})
export class SignInComponent implements OnInit {



  newUser = new NewUser()

  signUpLoading = false
  passwordsAreValid = false
  didJustSignUp = false
  signupError: string



  model: any = {}
  signInLoading = false
  returnUrl: string
  signinError: string



  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private currentUserService: CurrentUserService
  ) { }

  ngOnInit() {
    this.currentUserService.signOut()
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
  }

  signIn() {
    this.signInLoading = true
    this.currentUserService.signIn(this.model.email, this.model.password)
      .subscribe(
        authResult => {
          this.router.navigate([this.returnUrl])
        },
        error => {
          this.signInLoading = false
          this.signinError = "Oops. Either your email or password is incorrect."
        })
  }

  checkPasswords() {
    this.passwordsAreValid = this.newUser.password.length >= 6 && this.newUser.password === this.newUser.passwordMatch
  }

  passwordClass() {
    return this.passwordsAreValid ? "valid" : "invalid"
  }

  registerUser() {
    if (!this.passwordsAreValid) { return }

    this.newUser.username = this.newUser.email

    this.signUpLoading = true
    this.currentUserService.signUp(this.newUser)
      .subscribe(
        authResultData => {
          const authResult = new AuthResult(authResultData)
          this.signupError = authResult.error
          this.signUpLoading = false
          this.didJustSignUp = true
        },
        error => {
          this.signUpLoading = false
        })
  }

}
