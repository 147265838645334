import { Component, OnInit } from '@angular/core'
import { PasswordResetService } from '../../../services/password-reset.service'

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.less']
})
export class PasswordResetComponent implements OnInit {



  email: string
  response: string



  constructor(
    private passwordResetService: PasswordResetService
  ) { }

  ngOnInit() {
  }

  sendResetEmail() {
    this.passwordResetService.postPasswordResetToken(this.email).subscribe(result => {
      console.log(result)
      if (result.result === 'ok') {
        this.response = 'Reset email sent!'
      } else if (result.result === 'user not found') {
        this.response = 'Email address not found.'
      } else {
        this.response = 'Something went wrong.'
      }
    })
  }

}
