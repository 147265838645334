import { Component, OnInit } from '@angular/core'
import { ProjectDataService } from '../../../services/project-data.service'
import { Project } from '../../../models/project'
import { Router } from '@angular/router'

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.less']
})
export class CreateProjectComponent implements OnInit {



  newProject = new Project({})



  constructor(
    private projectDataService: ProjectDataService,
    private router: Router
  ) { }



  ngOnInit() {
  }

  saveProject() {
    this.projectDataService.postProject(this.newProject).subscribe(project => {
      this.projectDataService.projects.unshift(project)
      window.location.href = `/assets/twine?projectId=${project.id}`
    })
  }

}
