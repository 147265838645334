import { Component, OnInit } from '@angular/core';
import { PasswordResetService } from '../../../services/password-reset.service'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-password-verify',
  templateUrl: './password-verify.component.html',
  styleUrls: ['./password-verify.component.less']
})
export class PasswordVerifyComponent implements OnInit {



  token: string
  tokenIsValid = false

  password: string
  passwordMatch: string

  passwordsAreValid = false

  loading = false
  didChangePassword = false
  signinError: String



  constructor(
    private passwordResetService: PasswordResetService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token')
    this.checkPasswordResetToken()
  }

  checkPasswordResetToken() {
    this.passwordResetService.getCheckPasswordResetToken(this.token).subscribe(result => {
      if (result.result === 'ok') {
        this.tokenIsValid = true
      } else {
        this.tokenIsValid = false
      }
    })
  }

  checkPasswords() {
    this.passwordsAreValid = this.password.length >= 6 && this.password === this.passwordMatch
  }

  passwordClass() {
    return this.passwordsAreValid ? 'valid' : 'invalid'
  }

  saveNewPassword() {
    this.loading = true
    this.passwordResetService.postNewPassword(this.password, this.token).subscribe(result => {
      console.log(result)
      this.loading = false
      if (result.result === 'ok') {
        this.didChangePassword = true
      } else {
        this.signinError = "Something went wrong."
      }
    })
  }

}
