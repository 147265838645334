import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { User } from '../models/user'
import { NewUser } from '../models/new_user'
import { AuthResult } from '../models/auth_result'
import {ProjectDataService} from './project-data.service';

@Injectable()
export class CurrentUserService {



  private signUpPath = '/api/1.0/auth/signup'
  private signInPath = '/api/1.0/auth/signin'
  private signOutPath = '/api/1.0/auth/signout'
  private currentUserPath = '/api/1.0/auth/user'

  private origin = ""





  public currentUser: User

  constructor(
    private http: HttpClient,
    private projectDataService: ProjectDataService
  ) {
    const userData = JSON.parse(localStorage.getItem('currentUser'))
    this.setCurrentUser(userData)
    this.getCurrentUser()
  }

  setCurrentUser(userData: any) {

    const lastCurrentUserId = this.currentUser ? this.currentUser.id : null
    this.currentUser = userData ? new User(userData) : null

    if (this.currentUser) {
      localStorage.setItem('currentUser', JSON.stringify(this.currentUser))

      if (lastCurrentUserId == null || lastCurrentUserId !== this.currentUser.id) {
        this.projectDataService.projects = []
        this.projectDataService.getMyProjects().subscribe(projects => {
          this.projectDataService.projects = projects
        })
      }

    } else {
      localStorage.removeItem('currentUser')
      this.projectDataService.projects = []
    }
  }

  getCurrentUser() {
    this.http.get<any>(`${this.origin}${this.currentUserPath}`).pipe(
      map(authResultData => {
        const authResult = new AuthResult(authResultData)
        this.setCurrentUser(authResult.user)
        return authResult
      })
    ).subscribe()
  }

  signUp(newUser: NewUser) {
    return this.http.post<AuthResult>(`${this.origin}${this.signUpPath}`, newUser).pipe(
      map(authResultData => {
        const authResult = new AuthResult(authResultData)
        this.setCurrentUser(authResult.user)
        return authResult
      })
    )
  }

  signIn(email: string, password: string) {
    return this.http.post<AuthResult>(`${this.origin}${this.signInPath}`, { username: email, password: password }).pipe(
      map(authResultData => {
        const authResult = new AuthResult(authResultData)
        this.setCurrentUser(authResult.user)
        return authResult
      })
    )
  }

  signOut() {
    return this.http.get(`${this.origin}${this.signOutPath}`).pipe(
      map(data => {
        this.setCurrentUser(null)
        return data
      })
    )
  }

}
