import { Serializable } from './serializable'
import { ProjectVariable } from './project_variable';

export class Project extends Serializable {

  id: number
  name: string
  userId: number

  projectVariables: ProjectVariable[]
  projectVariablesByKey = {}

  createdAt: Date

  constructor(data: any) {
    super(data)
    if (data) {
      if (data.projectVariables) {
        this.projectVariables = data.projectVariables.map(projectVariable => new ProjectVariable(projectVariable))
      } else {
        this.projectVariables = [] as ProjectVariable[]
      }
      this.projectVariables.forEach(projectVariable => {
        this.projectVariablesByKey[projectVariable.key] = projectVariable.value
      })
    }
  }

  percentageComplete() {
    return this.projectVariables.length / 80 * 100
  }

}
