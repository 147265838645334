import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectDataService } from '../../../services/project-data.service';
import { Project } from '../../../models/project';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.less']
})
export class ReportComponent implements OnInit {



  project: Project



  constructor(
    private activatedRoute: ActivatedRoute,
    private projectDataService: ProjectDataService
  ) { }

  ngOnInit() {
    const projectId = this.activatedRoute.snapshot.paramMap.get('projectId')

    this.projectDataService.getMyProject(projectId).subscribe(project => {
      this.project = project
      console.log(this.project.projectVariables)
    })

  }

  now() {
    return Date.now()
  }

}
