import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Project } from '../models/project'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ProjectDataService {



  projects: Project[] = []



  constructor(
    private http: HttpClient
  ) {}



  refreshProjects(complete: () => void) {
    this.getMyProjects().subscribe(projects => {
      this.projects = projects
      complete()
    })
  }

  getMyProjects() {
    return this.http.get('/api/1.0/projects')
      .pipe(
        map(projectsData => {
          return projectsData["projects"].map(projectData => {
            return new Project(projectData)
          }).sort((a, b) => {
            return a.createdAt < b.createdAt ? 1 : -1
          })
        })
      )
  }

  getMyProject(projectId: string) {
    return this.http.get<Project>(`/api/1.0/project/${projectId}`)
      .pipe(
        map(projectData => {
          return new Project(projectData)
        })
      )
  }

  postProject(project: Project) {
    return this.http.post(`/api/1.0/projects`, {project})
      .pipe(
        map(projectData => {
          return new Project(projectData)
        })
      )
  }

  deleteProject(project: Project) {
    if (window.confirm("Are you sure you want to delete this? Please contact an admin to restore projects accidentally deleted.")) {
      this.http.post(`/api/1.0/projects/delete`, {project}).subscribe( result => {
        this.refreshProjects(() => {})
      })
    }
  }

}
